import React, { Component } from "react";

// Web Components
import Row from "./sessions-row";

class SessionsTable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		let sessions = [];
		if (this.props.sessions) {
			if (Array.isArray(this.props.sessions.data)) {
				sessions = this.props.sessions.data;
				if (this.props.filterStatus) {
					console.log(sessions);
					sessions = sessions.filter(session => session.IDSTATE == this.props.filterStatus)
					console.log('Sessiones filtradas:', sessions);
				}
				sessions = sessions.slice(((this.props.page - 1) * 12), (this.props.page * 12));
			}
		}
    	return (
			<div className="table-responsive">
				<table className="table-responsive table-sessions">
				<thead>
					<tr>
					<th> Date </th>
					<th> Start Time </th>
					<th> Duration </th>
					<th> Teacher </th>
					<th> Status </th>
					</tr>
				</thead>
				<tbody>
					{sessions.map((session, index) => (
					<Row key={index} session={session} user={this.props.user} setSession={this.props.setSession}/>
					))}
				</tbody>
				</table>
			</div>      
    	);
  	}
}

export default SessionsTable;
