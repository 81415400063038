import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";
import { getCredits, getNextSession } from "../../redux/actions";

// Web Components
import AsideCredit from "./aside-credit";
import AsideSession from "./aside-session";
import moment from "moment-timezone";

class Aside extends Component {
	constructor(props) {
		super(props);
		this.state = {
			nextSession: ''
		};
		this.load();
	}

	componentDidMount() {
		const active = this.props.current;
		setTimeout(() => {
			let linkActive = document.getElementById(active);
			if (linkActive) {
				linkActive.classList.add("color-orange");
			}
		}, 500);
	}

	async load() {
		const credits = await getCredits();
		if( credits.message ) {
		this.setState({credit: credits.message});
		}
		if( credits.credits) {
		const total = credits.credits.reduce((a, b)=> a + b.CREDIT_VALUE, 0);
		this.setState({credit: total});
		}

		const nextSession = await getNextSession({USERNAME: this.props.user.email});
		if( nextSession.data ) {
		const dif = moment(nextSession.data.NEXTCLASS).diff(moment());
		if (  dif < 0) {
			this.setState({
				nextSession: 'You do not have upcoming classes'
			});
		} else {
			this.setState({
				nextSession: moment(nextSession.data.NEXTCLASS).tz(this.props.user.utc).format('YYYY-MM-DD hh:mm a'),
				skypeID: nextSession.data.SKYPE,
				STUDENT_URL: nextSession.data.STUDENT_URL
			});
		}
		}
	}

  	render() {
    	return (
      		<div className="user-aside column">
        		<div className="aside-head auto">
					<h4 className="color-white">
						<i className="fas fa-bars" />
						&nbsp; OPTIONS
					</h4>
        		</div>
				{this.props.user.admin || this.props.user.teacher ? (
					<div className="column full">
						<div className="aside-item">
							<Link to="/admin/profile#to-profile" className="link" id="active-profile">
								<i className="fas fa-angle-right" /> My Profile
							</Link>
						</div>
						<div className="aside-item">
							<Link to="/admin/sessions#to-sessions" className="link" id="active-sessions">
								<i className="fas fa-angle-right" /> Sessions
							</Link>
						</div>
						<div className="aside-item">
							<Link to="/admin/calendar#to-calendar" className="link" id="active-calendar">
								<i className="fas fa-angle-right" /> Calendar
							</Link>
						</div>
						{ this.props.user.admin ?
						(
						<React.Fragment>
							<div className="aside-item">
								<Link to="/admin/users#to-users" className="link" id="active-users">
									<i className="fas fa-angle-right" /> Users
								</Link>
							</div>
							<div className="aside-item">
								<Link to="/admin/credits" className="link" id="active-credits">
									<i className="fas fa-angle-right" /> Credits
								</Link>
							</div>
							<div className="aside-item">
								<Link to="/admin/reports" className="link" id="admin-reports">
									<i className="fas fa-angle-right" /> Reports
								</Link>
							</div>
						</React.Fragment>
						) : ("")
					}            
					</div>
				) : (
					<div className="column">
						<div className="aside-item">
							<Link to="/user/profile#to-profile" className="link" id="active-profile">
							<i className="fas fa-angle-right" /> My Profile
							</Link>
						</div>
						<div className="aside-item">
						<Link to="/user/schedule#to-schedule" className="link" id="active-schedule">
							<i className="fas fa-angle-right" /> Schedule a Session
						</Link>
						</div>
						<div className="aside-item">
							<Link to="/user/sessions#to-sessions" className="link" id="active-sessions">
							<i className="fas fa-angle-right" /> My Sessions
						</Link>
						</div>
						<div className="aside-item">
						<Link to="/user/buy#to-buy" className="link" id="active-buy">
							<i className="fas fa-angle-right" /> Buy Credit
						</Link>
						</div>
						<div className="aside-item">
							<Link to="/user/teachers#to-teachers" className="link" id="active-teachers">
							<i className="fas fa-angle-right" /> Teachers
						</Link>
						</div>
						<AsideCredit credit={this.state.credit} />
						<AsideSession session={this.state.nextSession} skype={this.state.skypeID} STUDENT_URL={this.state.STUDENT_URL}/>
					</div>
				)}
      		</div>
    	);
  	}
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user
  };
}

export default connect(mapStateToProps)(Aside);
