import React, { Component } from 'react';
import moment from 'moment-timezone';

class SessionsRow extends Component {

    constructor(props){
        super(props);
        this.state = {
            clase: this.props.clase
        }
    }

    render() {
        return(
            <tr onClick={this.appearModal.bind(this, this.props.clase)}>
                <td>{`${moment(this.props.clase.CLASS_DATETIME).tz(this.props.utc).format("YYYY-MM-DD HH:mm a")}`}</td>
                <td>{`${this.props.clase.DURATION} minutes`}</td>
                <td>{this.props.clase.STATE}</td>
                <td>{this.props.clase.STUDENT}</td>
                <td>{this.props.clase.TEACHER}</td>
                {/*<td>{this.props.clase.QUANTITY}</td>*/}
            </tr>
        );
    }

    appearModal(clase) {
        this.props.selectClase(clase);
        document.getElementById("modal-feedback").classList.remove("close-modal");
        document.getElementById("modal-feedback").classList.remove("hidden");
    }
}

export default SessionsRow;