import React from 'react';
import { Helmet } from 'react-helmet';
import { getTeachers } from "../redux/actions";
import { URL_API } from "../core/urlsApi";
import ImageLoader from "react-loading-image";

// Images
import Images from '../img/index';


class TechersPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            teachers: [],
            loading: null
        }

        this.getTeachers();
    }

    imgError(event) {
        event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png";
    }

    async getTeachers() {
        const teachers = await getTeachers();
        this.setState({
            loading: false
        })
        if ( teachers ) {
            this.setState({ teachers: teachers.teachers, loading: true })
        }
    }

    render() {

        let teachers = [];
        if (this.state.teachers) {
            teachers = this.state.teachers
        }
        
        return(

            <div className="user-admin justify-center">
                <Helmet>
                    <title>BéCé - Teachers</title>
                    <meta property="og:title" content="BéCé - Teachers" />
                    <meta property="og:url" content={`http://www.beceonline.com${this.props.location.pathname}`} />
                </Helmet>
                <div className="container column">
                    <div className="white-space-32"></div>
                    <div className="row-responsive break-row">
                    
                        <div className="user-area">
                            <div className="teachers-page column">
                                <h2 className="color-orange">
                                    TEACHERS
                                </h2>
                                <div className="white-space-16"></div>
                                <h3 className="color-orange">
                                    Our certificates
                                </h3>
                                <div className="white-space-32"></div>
                                <div className="row-responsive responsive-img">
                                    <img src={Images['cambridge4']} alt="Cambridge Certificate" title="Cambridge Certificate" />
                                    <img src={Images['cambridge2']} alt="Cambridge Certificate" title="Cambridge Certificate" />
                                    <img src={Images['cambridge3']} alt="Cambridge Certificate" title="Cambridge Certificate" />
                                </div>
                                <div className="white-space-32"></div>
                                <h3 className="color-orange">
                                    Do you want to know about our teachers?
                                </h3>
                                <div className="white-space-32"></div>
                                <div className="teachers column">
                                    <div className="teachers-list column">
                                        {
                                            this.state.loading ? (
                                            teachers.map((teacher) => (
                                                <div className="teachers-list-item row-responsive">
                                                    <div className="teacher-picture auto">
                                                        <ImageLoader src = { URL_API + "/users/image/?idImage=" + teacher.IDIMAGE } 
                                                        image = { props => <img src = { props.src } alt="Teacher Profile" />}
                                                        loading={() => <img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading" />}
                                                        onError = { this.imgError.bind(this) } />
                                                    </div>
                                                    <div className="teacher-data column justify-center">
                                                        <p className="color-orange weight-bold">
                                                            { teacher.NAME }
                                                        </p>
                                                        <div className="white-space-8"></div>
                                                        <p className="text-justify">
                                                            <b>Nationality: </b>
                                                            { teacher.NATIONALITY } <br />
                                                            Languages: <br />
                                                            { teacher.LANGUAJES } <br />
                                                            <b>Background: </b>
                                                            { teacher.BACKGROUND } <br />
                                                            <b>Teaching experience: </b>
                                                            { teacher.EXPERIENCE }. <br />
                                                            <b>Specialist subjects: </b>
                                                            { teacher.SPECIALIST } <br />
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                            ) : (<div className="justify-center">
                                                    <i className="fas fa-spin fa-spinner"></i>
                                                </div> 
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default TechersPage;