import React, { Component } from "react";
import { connect } from "react-redux";
import { registerUSER } from "../redux/actions";
import { Helmet } from 'react-helmet';
import Terms from '../files/BC_Terms.pdf';

class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: "",
			timezone: "GMT"
		};
	}

	cipher(text) {
		const string64 = new Buffer(text).toString("base64");
		return string64;
	}

	submit(event) {
		event.preventDefault();
		const form = event.target;

		if (form.exams.checked) {
			let topics = "";
			const checkboxes = form.business;
			for (var i = 0; i < checkboxes.length; i++) {
				if (checkboxes[i].checked) {
					topics = topics + checkboxes[i].value + "|";
				}
			}
			const password = this.cipher(form.password.value);
			const data = {
				name: form.name.value,
				email: form.email.value,
				password: password,
				skype: form.skype.value,
				nationality: form.nationality.value,
				timezone: form.timezone.value,
				topics: topics
			};

			// envia el registro al API
			this.register(data);
		} else {
			this.setState({
				message: "You must read and accept the terms and conditions to continue"
			});
			document.getElementById("modal-register").classList.remove("close-modal");
			document.getElementById("modal-register").classList.remove("hidden");
		}
	}

	async register(data) {
		const reg = await registerUSER(data);
		if (reg) {
			this.setState({ message: reg.message });
			document.getElementById("modal-register").classList.remove("close-modal");
			document.getElementById("modal-register").classList.remove("hidden");

			if (reg.data) {
				if (reg.data.auth > 0) {
					window.location = "/user/help";
				}
			}
		}
	}

	closeModal() {
		document.getElementById("modal-register").classList.add("close-modal");
		setTimeout(() => {
			document.getElementById("modal-register").classList.add("hidden");
		}, 300);
	}

	renderModal() {
		return (
			<div className="flex modal justify-center hidden" id="modal-register">
				<Helmet>
					<title>BéCé - Register</title>
					<meta property="og:title" content="BéCé - Register" />
					<meta property="og:url" content={`http://www.beceonline.com${this.props.location.pathname}`} />
				</Helmet>
				<div className="container modal-content column">
					<div className="modal-header justify-center">
						<h2 className="color-white">Message</h2>
					</div>
					<div className="modal-body column">
						<div className="white-space-16" />
						<div className="confirmation-data column">
							<div className="white-space-16" />
							<h3 className="color-orange weight-bold text-center">
								{this.state.message}
							</h3>
							<div className="white-space-24" />
						</div>
						<div className="controls-group">
							<div className="cancel">
								<button
									className="btn btn-red"
									data-close="book"
									onClick={this.closeModal.bind(this)}
								>
									<i className="fas fa-times" />
									&nbsp; CLOSE
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

  changeTime(event) {
    console.log(event.target.value);
    this.setState({ timezone: event.target.value });
  }

  render() {
    let timezones = this.props.utc ? this.props.utc.data : [];
    let topics = this.props.topics ? this.props.topics.data : [];
    let nationalities = this.props.nationalities ? this.props.nationalities.data : [];
    return (
      <div className="introduction justify-center">
        <div className="container">
          <form className="column" onSubmit={this.submit.bind(this)}>
            <div className="row-responsive">
              <div className="left column">
                <div className="white-space-16" />
                <h1 className="color-orange">CREATE AN ACCOUNT</h1>
                <div className="white-space-24" />
                <h3 className="color-orange">Personal Info</h3>
                <div className="white-space-32" />
                <div className="input-group align-center">
                  <label htmlFor="name">Full Name:</label>
                  <input type="text" name="name" className="input input-text" required maxLength="64" />
                </div>
                <div className="white-space-8" />
                <div className="input-group align-center">
                  <label htmlFor="email">Email:</label>
                  <input type="email" name="email" className="input input-text" required maxLength="80" />
                </div>
                <div className="white-space-8" />
                <div className="input-group align-center">
                  <label htmlFor="password">Password:</label>
                  <input type="password" name="password" className="input input-text" required minLength="6" maxLength="24" />
                </div>
                <div className="white-space-8" />
                <div className="input-group align-center">
                  <label htmlFor="repassword">Confirm:</label>
                  <input type="password" name="repassword" className="input input-text" required minLength="6" maxLength="16" />
                </div>
                <div className="white-space-8" />
                <div className="input-group align-center">
                  <label htmlFor="skype">Skype:</label>
                  <input type="text" name="skype" className="input input-text" required />
                </div>
                <div className="white-space-8" />
                <div className="input-group align-center">
                  <label htmlFor="timezone">Nationality:</label>
                  <select name="nationality" id="nationality" className="input input-select">
                    { this.props.nationalities_loading ? (
                      <option value="0">{"loading..."}</option>
                    ) : (
                      nationalities.map((nat, index) => (
                        <option value={nat.alpha_3_code} key={index}>
                          {nat.nationality}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="white-space-8" />
                <div className="input-group align-center">
                  <label htmlFor="timezone">Time Zone:</label>
                  <select name="timezone" id="timezone" className="input input-select" onChange={this.changeTime.bind(this)} >
                    {this.props.utc_loading ? (
                      <option value="0">{"loading..."}</option>
                    ) : (
                      timezones.map((tmz, index) => (
                        <option value={tmz.zona} key={index}>
                          {tmz.zona}
                        </option>
                      ))
                    )}
                  </select>
                </div>
                <div className="white-space-8" />
              </div>
              <div className="right column">
                <div className="white-space-64" />
                <h3 className="color-orange">Select Topics of Interest</h3>
                <div className="white-space-16" />

                {this.props.topics_loading ? (
                  <div className="align-center">
                    <i className="fas fa-spinner fa-spin" />
                  </div>
                ) : (
                  topics.map((topic, index) => (
                    <span key={index}>
                      <div className="input-group align-center">
                        <input
                          type="checkbox"
                          name="business"
                          value={topic.IDTOPIC}
                          className="input-checkbox"
                        />
                        &nbsp; {topic.NAME}
                      </div>
                      <div className="white-space-8" />
                    </span>
                  ))
                )}
              </div>
            </div>
            <div className="white-space-32" />
            <div className="input-group align-center">
              <input type="checkbox" name="exams" value="exams" className="input-checkbox" /> &nbsp;
              <a href={Terms} target="_blank">
                I have read Terms & conditions
              </a>
            </div>
            <div className="white-space-16" />
            <div className="btn-container">
              <button type="submit" className="btn btn-orange">
                <i className="fas fa-plus-circle" />
                &nbsp; CREATE AN ACCOUNT &nbsp;
                {this.props.register_loading ? (
                  <i className="fas fa-spinner fa-spin" />
                ) : null}
              </button>
            </div>
          </form>
        </div>
        {this.renderModal()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    utc: state.utc,
    topics: state.topics,
    nationalities: state.nationalities,
    utc_loading: state.utc_loading,
    topics_loading: state.topics_loading,
    nationalities_loading: state.nationalities_loading,
    register_loading: state.register_loading
  };
}

export default connect(mapStateToProps)(Register);
