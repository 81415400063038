import React from "react";
import moment from "moment-timezone";

const OtherDayModal = (props) => {
	return (
		<div className="flex justify-center modal hidden" id="modal-otherday">
			<div className="container modal-content column">
				<div className="modal-header justify-center">
					<div className="white-space-8"></div>
					<div className="close-x justify-end">
						<h3 className="color-white" onClick={closeModal.bind(this)}>x</h3>
					</div>
					<div className="white-space-8"></div>
				</div>
				<div className="modal-body column justify-center align-center">
            		<div className="white-space-16" />
					<div className="container">
						<h4 className="text-center">I’m afraid we have no availability for the requested day. Check the following options please.</h4>
					</div>
					<div className="white-space-16" />
					<h3 className="color-orange weight-medium">
						{moment(props.day).format('YYYY/MM/DD')}
					</h3>
					<div className="white-space-16" />
					<div className="justify-center">
						<button className="btn btn-orange" onClick={closeModal.bind(this)}>
							&nbsp; CLOSE
						</button>
					</div>
					<div className="white-space-8" />
				</div>
			</div>
		</div>
	)
}

function closeModal() {
	document.getElementById("modal-otherday").classList.add("close-modal");
    setTimeout(() => {
      document.getElementById("modal-otherday").classList.add("hidden");
    }, 300);
}

export default OtherDayModal;