import React, { Component } from "react";
import { connect } from "react-redux";
import { getSESSIONSByUser } from "../../redux/actions";

// Web Components
import Aside from "../../components/user/aside";
import SessionsTable from "../../components/user/tables/sessions-table";
import Modal from "../../components/user/sessions/modal";
import Paginator from "../../components/paginator";

class Sessions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			session: {},
			filterStatus: 0,
			page: 1
		};
	}

	componentDidMount() {
		this.getSessions();
	}

	async getSessions() {
		this.setState({ loading: true });
		const data = {
			USERNAME: this.props.user.email
		};
		const sessions = await getSESSIONSByUser(data);
		this.setState({ loading: false, message: "" });
		if (sessions) {
			this.setState({ sessions: sessions });
			if (sessions.message) {
				if (sessions.message !== "OK") {
					this.setState({ message: sessions.message });
				}
			}
		}
	}

	setSession(session) {
		this.setState({session: session, openModal: true});
	}

	changeFilter(event) {
		this.setState({ filterStatus: event.target.value });
	}

	setpage(page) {
		this.setState({ page: page });
	}

	render() {
		let sessions = [];
		if (this.state.sessions) {
			sessions = this.state.sessions;
		}

		return (
			<div className="user-admin justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<div className="row-responsive break-row">
						<Aside current={"active-sessions"} />
						{/*  Load Views Here */}
						<div className="user-area">
							<div className="schedule-page column">
								<h2 className="color-orange" id="to-sessions">MY SESSIONS</h2>
								<div className="white-space-16" />
								<p>Your Sessions & teachers' notes</p>
								<div className="white-space-16" />
								<button className="btn btn-orange" onClick={this.getSessions.bind(this)} >
									<i className="fas fa-spinner" />
									&nbsp; REFRESH SESSIONS 
								</button>
								<div className="white-space-16" />
								<div className="align-center">
									<h3 className="color-orange">
										Select Session Status:
									</h3>
								</div>
								<div className="white-space-16" />
								{/* Filter for Status  */}
								<select name="sessions" className="input input-select" onChange = {this.changeFilter.bind(this)}>
									<option value="">ALL</option>
									<option value="1">OPEN</option>
									<option value="2">CANCELLED</option>
									<option value="5">CANCELLED BY TEACHER</option>
									<option value="6">NO FEEDBACK</option>
									<option value="3">FINISHED</option>
									<option value="4">FAILED</option>
								</select>
								<div className="white-space-16" />
								<p className="swipe-to-left">
									Swipe to the right to see more
								</p>
								<div className="white-space-16" />
								{this.state.loading ? (
								<div className="justify-center">
									<i className="fas fa-spinner fa-spin" />
								</div>
								) : (
									<div className="column">
										<SessionsTable sessions={this.state.sessions} user={this.props.user} filterStatus={this.state.filterStatus} setSession={this.setSession.bind(this)} page={this.state.page} />
										<div className="white-space-16"></div>
										{sessions.length > 12 ?
										<Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(sessions.length / 12)} /> : null}
									</div>
								)}
								<div className="justify-center">
									<label>{this.state.message}</label>
								</div>
							</div>
						</div>
					</div>
					<div className="white-space-32" />
				</div>
				<Modal session = {this.state.session} openModal={this.state.openModal} onClose={() => this.setState({openModal: false})}/>
			</div>
		);
	}
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(Sessions);
