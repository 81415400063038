import React, { Component } from 'react';
import { connect } from 'react-redux';

class Row extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
        <tr>
            <td>{this.props.user.USERNAME} </td>
            <td>{this.props.user.NAME}</td>
            <td>{this.props.user.SKYPE}</td>
            <td>
                {this.props.user.ADM ? "ADMIN"
                    : this.props.user.TEACHER ? "TEACHER" : "STUDENT"
                }
            </td>
            <td>{this.props.user.CREDITS}</td>
            {
                this.props.auth &&
                (
                <td>
                    <button className="btn btn-small btn-orange" data-open="users" onClick={this.appearModal.bind(this, 'modal-users', this.props.user)} >
                        <i className="fas fa-pen"></i>&nbsp; EDIT
                    </button>
                </td>
                )
            }
			{
                this.props.auth &&
                (
                <td>
                    <button className="btn btn-small btn-orange" data-open="users" onClick={this.appearModal.bind(this, 'modal-users-sessions', this.props.user)} >
                        <i className="fas fa-calendar"></i>&nbsp; VIEW
                    </button>
                </td>
                )
            }
        </tr>
        )
    }
    appearModal(idModal, user) {
        this.props.currentUser(user);
		const modal = document.getElementById(idModal);
		if (modal) {
			modal.classList.remove("close-modal");
			modal.classList.remove("hidden");
			modal.classList.remove('hide-dinamic-modal');
			modal.classList.add('show-dinamic-modal');
		}
    }
}

function mapStateToProps(state) {
    return{
        auth: state.user.admin
    }
}

export default connect(mapStateToProps)(Row);