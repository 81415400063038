import React, { Component } from "react";
import { connect } from 'react-redux';
import Pay from './pay';
import ReactExport from "react-data-export";

// Web Components
import Aside from "../../components/user/aside";
import SessionSelect from "../../components/user/buy/sessions-select";
import BuyTable from "../../components/user/tables/buy-table";
import Paginator from "../../components/paginator";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class BuyPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 1
		};   
	}

	setpage(page) {
		this.setState({ page: page });
	}

	renderExport() {
		let data = [];

		if (this.props.credits) {
			data = this.props.credits;
		}

		return (
			<ExcelFile
				element = {
				<button className="btn btn-orange">
					EXPORT TO EXCEL &nbsp; <i className="fas fa-file-excel" />
				</button>
				}>
				<ExcelSheet filename={'Credits'} data={data} name="CREDITS">
				<ExcelColumn label="Date" value="CREDIT_DATE" />
				<ExcelColumn label="Action" value="CREDIT_TYPE" />
				<ExcelColumn label="Quantity" value="CREDIT_VALUE" />
				{/*<ExcelColumn label="Quantity" value="QUANTITY" />*/}
				</ExcelSheet>
			</ExcelFile>
		);
	}

	render() {
		let credits = [];

		if (this.props.credits) {
			credits = this.props.credits;
		}

		return (
			<div className="user-admin justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<div className="row-responsive break-row">
						<Aside current={"active-buy"} />
						{/*  Load Views Here */}
						<div className="user-area">
						<div className="buy-page column">
							<h2 className="color-orange" id="to-buy">PAY AS YOU WANT</h2>
							<div className="white-space-16" />
							<p>
								The PayPal platform gives you the flexibility to choose the
								best payment method for you. Choose between: 
							</p>
							<div className="white-space-16"></div>
							<p><b>A)</b> Use your debit or credit card.</p> 
							<div className="white-space-8"></div>
							<p><b>B)</b> Use your PayPal account. You can link your bank account, 
								debit, and credit cards to your PayPal account (and still earn 
								your reward points). That way, you can choose how you want to 
								pay, and never have to worry about keeping a balance in your 
								PayPal account.	
							</p> 
							<div className="white-space-16"></div>
							<p>For more information about Paypal	:	<a className="link underline" href="www.paypal.com" target="_blank">www.paypal.com</a>	</p>
							<div className="white-space-16" />
							<h4 className="color-orange">Paypal Security Information</h4>
							<div className="white-space-16" />
							<p className="text-justify">
								Buy credit through PayPal is easy and secure. Paypal delivers
								one of the most secure payment platforms in the world. No matter
								how you choose to pay, we don't charge you a transaction fee. 
							</p>
						
							<div className="white-space-8" />
							<p className="text-mini">
								For more information about Paypal Security : 	
								<a
									className="link underline" target="_blank" rel="noopener noreferrer" href="	https://www.paypal.com/uk/webapps/mpp/paypal-safety-and-security">
										https://www.paypal.com/uk/webapps/mpp/paypal-safety-and-security
								</a>
							</p>
							<div className="white-space-32" />
							<div className="align-center">
								<h3 className="color-orange">Select your option:</h3>
							</div>
							<div className="white-space-16" />
							<SessionSelect />
							<div className="white-space-16" />
							{
							this.props.optionPay > 0 ? <Pay amount={this.props.optionPay} />
							: ""
							}                
							<div className="white-space-64" />
							<div className="row-responsive">
							<div className="align-center">
								<h3 className="color-orange">Historic Credit</h3>
							</div>
							<div className="justify-end">
								{this.renderExport()}
							</div>
							</div>
							<div className="white-space-32" />
							<BuyTable loading={this.props.credits_loading} credits={this.props.credits} page={this.state.page}/>
							<div className="white-space-16"></div>
							{credits.length > 12 ?
							<Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(credits.length / 12)} /> : null}
						</div>
						</div>
					</div>
					<div className="white-space-32" />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
    return {
        user: state.user,
        credits: state.credits,
        credits_loading: state.credits_loading,
        optionPay: state.optionPay
    }
}
export default connect(mapStateToProps)(BuyPage);
