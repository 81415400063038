import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../redux/store";
import { getUTC, getTOPICS, getNAT } from "../redux/actions";

//Web Pages
import Home from "../pages/home";
import Login from "../pages/login";
import Register from "../pages/register";
import Pricing from "../pages/pricing";
import Teachers from "../pages/teachers";
import Recover from '../pages/recover';

// Users Web Pages
import Sessions from "../pages/user/sessions";
import Help from "../pages/user/help";
import Profile from "../pages/user/profile";
import Schedule from "../pages/user/schedule";
import TeachersPage from "../pages/user/teachers";
import BuyPage from "../pages/user/buy";

// User Web Pages
import AdminSessions from "../pages/admin/sessions";
import AdminCalendar from "../pages/admin/calendar";
import AdminHelp from "../pages/admin/help";
import AdminProfile from "../pages/admin/profile";
import AdminUsers from "../pages/admin/users";
import AdminCredits from "../pages/admin/credits";
import AdminReports from "../pages/admin/reports";

//Web Components
import Header from "../components/header";
import Footer from "../components/footer";

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.load(this);
  }

  async load() {
    if (!store.getState().utc) {
      await getUTC();
    }
    if (!store.getState().topics) {
      await getTOPICS();
    }
    if (!store.getState().nationalities) {
      await getNAT();
    }
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          store.getState().auth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )
        }
      />
    );
    return (
      <Provider store={store}>
        <BrowserRouter>
          <div className="flex main">
            <div className="column">
              	<Header />
              	<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/home" exact component={Home} />
					<Route path="/login" exact component={Login} />
					<Route path="/restore" exact component={Recover} />
					<Route path="/restore/:id" exact component={Recover} />
					<Route path="/register" exact component={Register} />
					<Route path="/pricing" exact component={Pricing} />
					<Route path="/teachers" exact component={Teachers} />
					<PrivateRoute path="/user" exact component={Help} />
					<PrivateRoute path="/user/help" exact component={Help} />
					<PrivateRoute path="/user/profile" exact component={Profile} />
					<PrivateRoute path="/user/sessions" exact component={Sessions} />
					<PrivateRoute path="/user/schedule" exact component={Schedule} />
					<PrivateRoute path="/user/teachers" exact component={TeachersPage} />
					<PrivateRoute path="/user/buy" exact component={BuyPage} />
					<PrivateRoute path="/admin" exact component={AdminHelp} />
					<PrivateRoute path="/admin/help" exact component={AdminHelp} />
					<PrivateRoute path="/admin/profile" exact component={AdminProfile} />
					<PrivateRoute path="/admin/sessions" exact component = {AdminSessions} />
					<PrivateRoute path="/admin/calendar" exact component={AdminCalendar} />
					<PrivateRoute path="/admin/users" exact component={AdminUsers} />
					<PrivateRoute path="/admin/credits" exact component={AdminCredits} />
					<PrivateRoute path="/admin/reports" exact component={AdminReports} />
              	</Switch>
              <Footer />
            </div>
          </div>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default Routes;
