import React, { Component } from 'react';
import { updatePassword } from "../../../redux/actions";

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
			message: '',
			loading: false
        }
	}

    cipher(text) {
		const string64 = new Buffer(text).toString("base64");
		return string64;
    }

	async updatePassword(event) {
		event.preventDefault();
		this.setState({message: '', loading: true});
		const form = event.target;
		if( form.newpassword.value !== form.repassword.value ) {
			this.setState({message: 'The passwords not match'});
		} else {
			const data = {
				email: this.props.user,
				newpassword: this.cipher(form.newpassword.value)
			}
			const change = await updatePassword(data);
			// console.log(change)
			if(change.message) {
				this.setState({message: change.message});
			}
			if(change.update) {
				setTimeout(() => {
					this.closeModal(this);
					this.setState({message: ''});
				}, 1000);
			}
		}
		this.setState({loading: false});
	}

    render() {
        let user = [];
        if(this.props.ModalUser) {
            user = this.props.ModalUser;                     
        }
        return(
            <div className="flex modal justify-center hidden" id="modal_users_password">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            CHANGE PASSWORD
                        </h2>
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-16"></div>
                        <div className="confirmation-data column">
                            <div className="white-space-16"></div>
                            <form onSubmit={this.updatePassword.bind(this)} className="column justify-center align-center">
                                <div className="container column">
                                        <div className="white-space-16"></div>
                                        <div className="justify-center column">
                                            <label>New Password</label>
                                            <input name="newpassword" className="input-text" type="password" required />
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="justify-center column">
                                            <label>Retype Password</label>
                                            <input name="repassword" className="input-text" type="password" required />
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="justify-center">
                                            <label>{this.state.message}</label>
                                        </div>
                                        <div className="white-space-16"></div>
                                </div>
								<div className="controls-group">
                                    <div className="accept">
                                        <button type="submit" className="btn btn-orange">
                                            <i className="fas fa-check-double"></i>&nbsp; CONFIRM &nbsp;
											{ this.state.loading? <i className="fas fa-sync fa-spin"/> : null }
                                        </button>
                                    </div>
                                    <div className="cancel">
                                        <button type="button" className="btn btn-red" data-close="users" onClick={this.closeModal.bind(this)}>
                                            <i className="fas fa-times"></i>&nbsp; CLOSE
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    closeModal() {
        document.getElementById("modal_users_password").classList.add("close-modal");
        setTimeout(() => {
            document.getElementById("modal_users_password").classList.add("hidden");
        }, 300);
    }
}

export default Modal;