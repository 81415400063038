import React from "react";
import { connect } from 'react-redux';
const fillColor = { backgroundColor: '#f9f9f9'};
const padding = { padding: '8px 0' };
const skypeColor = { color:  '#0078d7'};

const AsideSession = props => {  
  	return (
    	<div className="next-aside column">
			<div className="next-head auto">
				<h4 className="color-white">
					<i className="fas fa-calendar-alt" />
					&nbsp; NEXT SESSION
				</h4>
			</div>
			<div className="column">
      		{props.session ? 
          		(props.session !== 'You do not have upcoming classes' ?
					(
						<div className="column full justify-center align-center" style={fillColor}>
							<p className="text-center weight-bold">
								{ props.session }
							</p> &nbsp;
							<div className="row justify-center">
							{
								props.STUDENT_URL? 
								<a href={`${props.STUDENT_URL}`} target="_newtab" className="zoom_button">
									JOIN ZOOM
								</a>
								:
								<a href={`skype:${props.skype}?call`}>
									<i className="fab fa-2x fa-skype color-orange" style={skypeColor}></i>
								</a>
							}
							</div>
						</div>
					) : (
						<p className="text-center">
							<b>
								{props.session}
							</b>                  
						</p>                
                	)
          		) :
				(
					<div className="justify-center align-center" style={padding}>
						<i className="fa-spin fas fa-spinner"></i>
					</div>
				)
      		}
			</div>
      		<div className="white-space-16"></div>
    	</div>
  	);
};

function mapStateToProps(state) {
	return {
		loading: state.sessions_loading
	}
};

export default connect(mapStateToProps)(AsideSession);
