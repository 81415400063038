import React, { Component } from "react";
import { connect } from "react-redux";
import datepicker from "js-datepicker";
import moment from 'moment-timezone';
import Request from '../../../core/httpClient';

const req = new Request();

class ReportControls extends Component {
	constructor(props) {
		super(props);
		this.state = {
			admin: this.props.user.admin,
			teacher: this.props.user.email,
			reportList: []
		};
	}

	componentDidMount() {
		const fromDate = datepicker("#from-date", {
			dateSelected: new Date()
		});
		const tiDate = datepicker("#to-date", {
			dateSelected: new Date()
		});
		this.loadReportList();
	}

	async loadReportList() {
		const res = await req.get('/reports/list');
		if (Array.isArray(res)) {
			this.setState({reportList: res});
		} else {
			console.error(res.message || 'Error on load report list');
		}
	}

	async loadReport(event) {
		event.preventDefault();
		this.setState({ loading: true });
		const { utc } = this.props.user;
		const form = event.target;
		const data = {
			ini: moment.tz(form.from_date.value, utc || 'GMT').format(),
			fin: moment.tz(form.to_date.value, utc || 'GMT').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format(),
			report: form.report.text,
			procedure: form.report.value
		};
		let res = await req.post('/reports', data);
		let resData = [];
		if(Array.isArray(res)) {
			resData = res;
		} else {
			resData= [];
		}
		this.props.getReport(resData);
		this.setState({ loading: false });
	}

	render() {
		return (
			<div className="admin-session-filter column">
				<form className="row-responsive" onSubmit={this.loadReport.bind(this)} >
					<div className="input-group align-center">
						<label htmlFor="duration">From Date:</label>
						<input type="text" name="from_date" className="input input-text" id="from-date" placeholder="Click to pick a date" readOnly />
					</div>
					<div className="input-group align-center">
						<label htmlFor="duration">To Date:</label>
						<input type="text" name="to_date" className="input input-text" id="to-date" placeholder="Click to pick a date" readOnly />
					</div>
					<div className="input-group align-center">
						<label htmlFor="report">Report:</label>
						<select name="report" id="report" className="input input-select" required>
							<option value="">SELECT REPORT</option>
							{this.state.reportList.map((report, index) => <option key={index} value={report.storedprocedure}>{report.name}</option>)}
						</select>
					</div>
					<div className="input-group align-center">
						{!this.state.loading ?
						<button className="btn btn-orange">
							FIND &nbsp; <i className="fas fa-search" />
						</button> :
						<button className="btn btn-orange">
							<i className="fas fa-spinner fa-spin" />
						</button>}
					</div>
				</form>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		user: state.user
	};
}

export default connect(mapStateToProps)(ReportControls);
