import React, { Component } from "react";
import { connect } from 'react-redux';

// Web Components
import Row from './users-row';

class UsersTable extends Component {
    render() {
        let users = [];
        if (Array.isArray(this.props.users)) {
            users = this.props.users.slice(((this.props.page - 1) * 12),(this.props.page * 12));
        }

        return (
            <div className="table-responsive">
                <table className="table-sessions table-auto">
                    <thead>
                        <tr>
                            <th> Email </th>
                            <th> Full Name </th>
                            <th> Skype </th>
                            <th> Roles </th>
                            <th> Credit </th>
                            {
                                this.props.auth ?
                                (
                                    <th> Option </th>
                                ) : ("")
                            }
							{
                                this.props.auth ?
                                (
                                    <th> Sessions </th>
                                ) : ("")
                            }
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            users ? 
                            (                                
                                users.map((user, index) => (
                                    <Row key = {index} user = {user} currentUser = {this.props.currentUser} />
                                ))
                            ) : null
                        }
                    </tbody>
                </table>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        auth: state.user.admin
    }
}

export default connect(mapStateToProps)(UsersTable);