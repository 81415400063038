import React, { Component } from 'react';
import PaypalExpressBtn from 'react-paypal-express-checkout';
import { buyCredits } from "../../redux/actions";
import { connect } from 'react-redux';

class Pay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ""
        };
    }

    async buyMethod(data) {
        const reg = await buyCredits(data);
        if (reg) {
            console.log(reg);
            this.setState({ message: reg.message });
            if (reg.data) {
                alert("Successful purchase");
                window.location.reload();
            }
        }
    }

    render() {
		let env;

		const client = { 
			production: 'AcxnceY7YKRdkyu012EoKv401SXsUPjcc6VFZz-zA15Nm6x317RbT9Y0jgBWhavdtW9m6O4Mpr4RK2Cg',
			sandbox:    'AWH49GPgDUfxd_m-M4mAk08l7ZJcX58OzYQ1kyMhe_XTJUYo0AXWcVFAFYIE-yvW7HvtNEkQkifZwXRL',
		};

		if (process.env.NODE_ENV === 'production') {
			env = 'production';
		} else {
			env = 'sandbox';
		}

		console.log(env);

        const onSuccess = (payment) => {
            let data = { 
                email: this.props.user.email,
                amount: this.props.amount
            }
            this.buyMethod(data);
        }

        const onCancel = (data) => {
            console.log('Payment cancelled!', data);
            alert('Pago Cancelado');
        }

        const onError = (err) => {            
            console.log("Error!", err);
            alert("Se encontró un error:", err);
        }
        return (
            <PaypalExpressBtn
				env			=	{env}
				client		=	{client}
				currency	=	{'EUR'}
				onCancel	=	{onCancel}
				onError		=	{onError}
				style		=	{{shape: 'rect', size: 'medium'}}
				onSuccess	=	{onSuccess} total={this.props.amount} />
        );
    }    
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Pay);