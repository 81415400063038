import React, { Component } from "react";
import CreditsTable from "../../components/admin/credits/table";
import { connect } from 'react-redux';
import { getCreditsAdmin } from "../../redux/actions";
import Aside from "../../components/user/aside";
import ReactExport from "react-data-export";
import datepicker from "js-datepicker";
import moment from 'moment-timezone';
import SessionModal from '../../components/admin/credits/session_modal';
import Paginator from "../../components/paginator";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Credits extends Component {
	constructor(props) {
		super(props);
		this.state = {
            credits: [],
            creditsFiltered: [],
            page: 1,
            filter: ''
		}
	}

	componentDidMount() {
		const fromDate = datepicker("#from-date", {
		  dateSelected: new Date()
		});
		const tiDate = datepicker("#to-date", {
		  dateSelected: new Date()
		});
	}

	async load(event) {
		event.preventDefault();
		const form = event.target;
		const data = {
			fechaIni: moment(form.from_date.value).format('YYYY-MM-DD'),
			fechaFin: moment(form.to_date.value).format('YYYY-MM-DD')
		};
		this.setState({loading: true});
		const res = await getCreditsAdmin(data);
		if(res.credits) {
			this.setState({credits: res.credits, creditsFiltered: res.credits});
		}
		this.setState({loading: false});
	}

	setpage(page) {
		this.setState({ page: page });
	}

	renderExport() {
		let data = [];
		if (this.props.credits) {
		  data = this.props.credits;
		  console.log(data);
		}
		return (
		  <ExcelFile
			element = {
			  <button className="btn btn-orange">
				EXPORT TO EXCEL &nbsp; <i className="fas fa-file-excel" />
			  </button>
			}>
			<ExcelSheet filename={'Credits'} data={data} name="CREDITS">
				<ExcelColumn label="User" value="USERNAME" />
			  	<ExcelColumn label="Date" value="CREDIT_DATE" />
			  	<ExcelColumn label="Action" value="CREDIT_TYPE" />
			  	<ExcelColumn label="Quantity" value="CREDIT_VALUE" />
			  	{/*<ExcelColumn label="Quantity" value="QUANTITY" />*/}
			</ExcelSheet>
		  </ExcelFile>
		);
	}

	selectClass(session) {
		this.setState({session});
		if (session > 0) {
			document.getElementById("modal-credit-session").classList.remove("close-modal");
			document.getElementById("modal-credit-session").classList.remove("hidden");
		}
	}

    filterResults() {
        if (Array.isArray(this.state.credits)) {
            const { filter } = this.state;
            const creditsFiltered = this.state.credits.filter(credit => {
                const {USERNAME, CREDIT_TYPE} = credit;
                return (
                    USERNAME.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1
                    || CREDIT_TYPE.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) !== -1
                )
            });
            this.setState({creditsFiltered});
        }
    }

	render() {
        let credits = [];
        const { creditsFiltered } = this.state;
		if (Array.isArray(creditsFiltered)) {
			credits = this.state.creditsFiltered
		}
		return (
			<div className="user-admin justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<div className="row-responsive break-row">
						<Aside current={"active-credits"}/>
						<div className="admin-area column">
                            <div className="sessions-page column">
								<h2 className="color-orange" id="to-sessions">Historic Credit</h2>
								<div className="white-space-32" />
                                <div className="admin-session-filter column">
                                    <form className="row-responsive" onSubmit={this.load.bind(this)} >
                                        <div className="input-group align-center">
                                            <label htmlFor="duration">From Date:</label>
                                            <input type="text" name="from_date" className="input input-text" id="from-date" placeholder="Click to pick a date" readOnly />
                                        </div>
                                        <div className="input-group align-center">
                                            <label htmlFor="duration">To Date:</label>
                                            <input type="text" name="to_date" className="input input-text" id="to-date" placeholder="Click to pick a date" readOnly />
                                        </div>
                                        <div className="input-group align-center">
											{!this.state.loading ?
                                            <button className="btn btn-orange">
                                                FIND &nbsp; <i className="fas fa-search" />
											</button> :
											<button className="btn btn-orange">
												<i className="fas fa-spinner fa-spin" />
											</button>}
                                        </div>
                                        <div className="row-responsive">
                                            <div>
                                                {this.renderExport()}
                                            </div>
                                        </div>
                                    </form>
									<div className="white-space-24"></div>
									<div className="row user-search">
										<input id="search_users" className="input input-text" value={this.state.filter} onChange={(event) => this.setState({filter: event.target.value})}></input>
										<div className="row btn-user-search">
											<button className="btn btn-orange" onClick={this.filterResults.bind(this)}>
												FIND &nbsp; <i className="fas fa-search" />
											</button>
										</div>
									</div>
                                </div>
							</div>
							<div className="white-space-32" />
							<CreditsTable selectClass={this.selectClass.bind(this)} page={this.state.page} credits={credits} loading={this.state.loading}/>
							{credits.length > 12 ?
								<Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(credits.length / 12)} /> : null} 
							<SessionModal session={this.state.session}/>
						</div>
					</div>
					<div className="white-space-64" />
				</div>
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
        user: state.user,
		credits: state.credits
	}
}

export default connect(mapStateToProps)(Credits);