import React, { Component } from "react";
import { connect } from "react-redux";
import { getTeachers } from "../../redux/actions";
import ReactExport from "react-data-export";
import moment from 'moment-timezone';

// Web Components
import Aside from "../../components/user/aside";
import SessionsTable from "../../components/admin/tables/sessions-table";
import ReportControls from "../../components/admin/reports/controls";
import Modal from "../../components/admin/sessions/modal";
import Paginator from '../../components/paginator';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class AdminReports extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clase: {},
			report: [],
			page: 1
		};
	}

	componentDidMount() {
		this.load();
	}

	async load() {
		const teachers = await getTeachers();
		if (teachers.teachers) {
			this.setState({ teachers: teachers.teachers });
		}
	}

	getReport(report) {
		this.setState({ report });
	}

	renderExport() {
		let data = [], columns = [], dataSetExcel = [];
		const { report } = this.state;
		if (Array.isArray(report)) {
			if( report.length > 0 ) {
				columns = Object.keys(report[0]);
				data = this.state.report;
			}
		}
		dataSetExcel = [
			{
				columns,
				data: data.map(row => {
					return columns.map(column => {
						let rowValue = row[column];
						if (moment(rowValue, moment.ISO_8601, true).isValid()) {
							console.log(rowValue);
							rowValue = moment.tz(rowValue, this.props.user.utc).format('YYYY-MM-DD HH:mm a')
						}
						return rowValue;
					})
				})
			}
		]
		return (
			<ExcelFile
				filename = {`Report ${moment().tz(this.props.user.utc).format('YYYYMMDD')}`}
				element = {
					<div className="column justify-end align-center">
						<button className="btn btn-orange">
							EXPORT &nbsp; <i className="fas fa-file-excel" />
						</button>
					</div>
				}>
				<ExcelSheet dataSet={dataSetExcel} name="Reporte" />
			</ExcelFile>
		);
	}

	selectClase(clase) {
		this.setState({clase: clase});
	}

	setpage(page) {
		this.setState({ page: page });
	}

  	render() {
		let columns = [];
		let dataSet = [];
		let dataSet_length = 0;
		if (Array.isArray(this.state.report)) {
			if (this.state.report.length > 0) {
				columns = Object.keys(this.state.report[0]);
				dataSet = this.state.report.map((row, i) => {
					return (
						<tr className="table-row" key={i}>
							{columns.map((column, index) => {
								let rowValue = row[column];
								if (moment(rowValue, moment.ISO_8601, true).isValid()) {
									console.log(rowValue);
									rowValue = moment.tz(rowValue, this.props.user.utc).format('YYYY-MM-DD HH:mm a')
								}
								return <td key={index}>{rowValue}</td>
							})}
						</tr>
					);
				});
				dataSet_length = dataSet.length;
				dataSet = dataSet.slice(((this.state.page - 1) * 12), (this.state.page * 12));
			}
		}

		return (
			<div className="user-admin reports-admin justify-center">
				<div className="container column">
					<div className="white-space-32" />
					<div className="row-responsive break-row">
						<Aside current={"admin-reports"} />
						{/*  Load Views Here */}
						<div className="admin-area">
							<div className="sessions-page column">
								<h2 className="color-orange" id="to-sessions">REPORTS</h2>
								<div className="white-space-32" />
								<div className="row justify-end">
									{this.renderExport()}
								</div>
								<div className="white-space-8"></div>
								<div className="row align-center justify-between">
									<ReportControls
										getReport={this.getReport.bind(this)}
									/>
								</div>
								<div className="white-space-16" />
								<p className="swipe-to-left">
									Swipe to the right to see more
								</p>
								<div className="white-space-16" />
								<div className="report-table row justify-start align-center">
									<div className="table-responsive column">
										<table className="table-sessions table-auto" id="table-admin-sessions">
											<thead>
												<tr className="text-center">
													{columns.map((column, index) => <th key={index}>{column}</th>)}
												</tr>
											</thead>
											<tbody>
												{
													dataSet.map((row, index) => { return (row) })
												}
											</tbody>
										</table>
										<div className="white-space-16"></div>
										{dataSet_length > 12 ?
										<Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(dataSet_length / 12)} /> : null}
									</div>
								</div>
								<div className="white-space-32" />
							</div>
						</div>
					</div>
					<div className="white-space-32" />
				</div>
				<Modal clase={this.state.clase} />
			</div>
    	);
  	}
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default connect(mapStateToProps)(AdminReports);
