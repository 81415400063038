import React, { Component } from "react";
import moment from "moment-timezone";
import { updateComments } from "../../../redux/actions";
import { connect } from 'react-redux';
const skypeColor = { color:  '#0078d7'};

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commentUser: this.props.clase.COMMENTS,
            commentTeacher: this.props.clase.TEACHER_COMMENTS,
            admin: this.props.admin,
            teacher: this.props.teacher,
			status: this.props.clase.IDSTATE,
			cancelReason: this.props.clase.CANCEL_REASON || ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.openModal !== prevProps.openModal) {
			console.log(this.props);
			const select = document.getElementById("select-status");
			if (select) {
				select.value = this.props.clase.IDSTATE || '1';
			}
			this.setState({
				cancelReason: this.props.clase.CANCEL_REASON || '',
				status: this.props.clase.IDSTATE
			});
		}
        if (this.props.clase !== prevProps.clase) {
			const select = document.getElementById("select-status");
			if (select) {
				select.value = this.props.clase.IDSTATE || '1';
			}
            this.setState({ 
                commentTeacher: this.props.clase.TEACHER_COMMENTS || '', 
                commentUser: this.props.clase.COMMENTS || '',
				status: this.props.clase.IDSTATE,
				cancelReason: this.props.clase.CANCEL_REASON || ''
            });
        }
    }

    handleTeacher(event) {
        this.setState({ commentTeacher: event.target.value });
    }

    handleUser(event) {
        this.setState({ commentUser: event.target.value });
    }

    handleUpdate() {
        let data = {
            idSchedule: this.props.clase.IDSCHEDULE,
            commentsUser: this.state.commentUser,
            commentsTeacher: this.state.commentTeacher,
			status: this.state.status,
			user: this.props.user,
			cancelReason: this.state.cancelReason
        }
        this.updateCommentsMethod(data);
    }

    async updateCommentsMethod(data) {
        this.setState({message: '', loading: true});
        if( parseFloat(this.props.clase.IDSTATE) === 1) {
            const updateValue = await updateComments(data);
            if (updateValue.message) {
                this.setState({ message: updateValue.message });
            }
            if (updateValue.data) {
                setTimeout(()=> {
                    window.location.reload();
                }, 1500)
            }
        }
        this.setState({loading: false});
    }

    render() {
		// console.log(this.props.clase);
        return (            
            <div className="flex modal justify-center hidden" id="modal-feedback">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            SEND FEEDBACK
                        </h2>
                        <div className="close-x justify-end">
                            <h3 className="color-white" onClick={this.closeModal.bind(this)}>x</h3>
                        </div>
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-32"></div>
                        <div className="confirmation-data justify-center">
                            <div className="container">
                                <div className="column">
                                    <div className="flex row justify-between">
                                        <div className="column">
                                            <p className="text-mini">
                                                Teacher: <b> {this.props.clase.TEACHER} </b>
                                            </p>
                                            <div className="white-space-8"></div>
                                            <p className="text-mini">
                                                Start Time:&nbsp;
										        <b>
                                                    {
                                                        `${moment(this.props.clase.CLASS_DATETIME).tz(this.props.timezone.utc).format("YYYY-MM-DD")} 
														${moment(this.props.clase.CLASS_DATETIME).tz(this.props.timezone.utc).format("HH:mm a")}`
                                                    }
                                                </b>
                                            </p>
                                        </div>
                                        <div className="column">
											<p className="text-mini">
                                                Student: <b> {this.props.clase.STUDENT} </b>
                                            </p>
											{this.props.clase.TEACHER_URL?
											<p className="text-mini row">
												<a href={`${this.props.clase.TEACHER_URL}`} target="_newtab" className="zoom_button">
													JOIN ZOOM
												</a>
											</p>
											:
                                            <p className="text-mini">
                                                Skype:&nbsp;
										        <a href={`skype:${this.props.clase.STUDENT_SKYPE}?call`}>
                                                    <i className="fab fa-2x fa-skype color-orange" style={skypeColor}></i>
                                                    <b> {this.props.clase.STUDENT_SKYPE} </b>
                                                </a>
                                            </p>
											}
										</div>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="request justify-center">
                                        <textarea name="request" rows="8" disabled={!(this.state.teacher && this.state.admin)} className="input textarea" maxLength="800" placeholder="User comments"
                                            value={this.state.commentUser} onChange={this.handleUser.bind(this)}>
                                        </textarea>
                                    </div>
                                    <div className="white-space-16"></div>
                                    <div className="request justify-center">
                                        <textarea name="request" rows="8" disabled={!(this.state.teacher || this.state.admin)} className="input textarea" maxLength="800" placeholder="Teacher comments"
                                            value={this.state.commentTeacher} onChange={this.handleTeacher.bind(this)}>
                                        </textarea>
                                    </div>
                                    <div className="white-space-16"></div>
                                    {
                                        this.props.teacher ? (
                                            <div className="row justify-center align-center">
                                                <div className="row align-center">
                                                    <p>
                                                        Status: &nbsp;
                                                    </p>
                                                    <select name="status" id="select-status" className="input input-select" value={this.state.status}
                                                        onChange={(event) => {
                                                            if (parseFloat(this.props.clase.IDSTATE) === 1) {
                                                                this.setState({ status: event.target.value });
                                                            }
                                                        }
                                                        }>
                                                        {this.props.clase.IDSTATE == "6" ?
                                                            <option value="6">NO FEEDBACK</option>
                                                            :
                                                            this.props.clase.IDSTATE == "2" ?
                                                                <option value="2">CANCELLED BY STUDENT</option>
                                                                :
                                                                this.props.clase.IDSTATE == "5" ?
                                                                    <option value="5">CANCELLED</option>
                                                                    :
                                                                    <React.Fragment>
                                                                        <option value="1">OPEN</option>
                                                                        <option value="5">CANCEL</option>
                                                                        <option value="3">FINISHED</option>
                                                                        <option value="4">FAILED</option>
                                                                    </React.Fragment>
                                                        }
                                                    </select>
                                                </div>
                                                <div className="row full justify-start align-center">
                                                    {this.state.status == 2 &&
                                                        <input style={{ width: '90%' }} className="input input-text" placeholder="No studen cancell reason" disabled={true}
                                                            value={this.state.cancelReason || ''}
                                                            onChange={(event) => this.setState({ cancelReason: event.target.value })} required></input>
                                                    }
                                                </div>
                                            </div>
                                        ) : (null)
                                    }
                                    <div className="white-space-8"></div>
                                    <p>
                                        {this.state.message}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="white-space-24"></div>
                        <div className="controls-group">
                            <div className="accept">
                            {
                                this.state.commentTeacher ?
                                    (
                                        this.state.commentTeacher.length > 10 ?
                                        (
                                            <button className="btn btn-orange" onClick={this.handleUpdate.bind(this)}>
                                                <i className="fas fa-check-double" />
                                                &nbsp; SEND {this.state.loading ? (
                                                    <i className="fas fa-sync fa-spin" />
                                                ) : null}
                                            </button>
                                        )
                                        : (
                                            <button className="btn btn-orange" disabled={true}>
                                                <i className="fas fa-check-double" />
                                                &nbsp; SHORT FEEDBACK {this.state.loading ? (
                                                    <i className="fas fa-sync fa-spin" />
                                                ) : null}
                                            </button>
                                        )
                                    )
                                    :
                                    (
                                        <button className="btn btn-orange" disabled={true}>
                                            <i className="fas fa-check-double" />
                                            &nbsp; REQUIRED FEEDBACK {this.state.loading ? (
                                                <i className="fas fa-sync fa-spin" />
                                            ) : null}
                                        </button>
                                    )
                            }                            
                            </div>
                            <div className="cancel">
                                <button className="btn btn-red" data-close="book" onClick={this.closeModal.bind(this)} >
                                    <i className="fas fa-times" />
                                    &nbsp; CLOSE
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    closeModal() {
        document.getElementById("modal-feedback").classList.add("close-modal");
        setTimeout(() => {
            document.getElementById("modal-feedback").classList.add("hidden");
		}, 300);
		if (this.props.onClose) {
			this.props.onClose();
		}
    }
}

function mapStateToProps(state) {
    return {
        timezone: state.user,
        teacher: state.user.teacher,
		admin: state.user.admin,
		user: state.user
    }
}

export default connect(mapStateToProps)(Modal);
