import React, { Component } from "react";

// Web Components
import SessionsRow from "./sessions-row";

class SessionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let classes = [];
    if (this.props.classes) {
      classes = this.props.classes.slice(
        (this.props.page - 1) * 12,
        this.props.page * 12
      );
    }
    return (
      <table className="table-sessions table-auto" id="table-admin-sessions">
        <thead>
          <tr>
            <th> Start Time </th>
            <th> Duration </th>
            <th> Status </th>
            <th> Student </th>
            <th> Teacher </th>
            {/*<th> Quantity </th>*/}
          </tr>
        </thead>
        <tbody>
          {this.props.message ? (
            <tr>
              <td colSpan={6}>{this.props.message}</td>
            </tr>
          ) :            
            classes.map((clase, index) => (
              <SessionsRow key={index} clase={clase} utc={this.props.utc} selectClase={this.props.selectClase}/>
            ))
          }
        </tbody>
      </table>
    );
  }
}

export default SessionsTable;
