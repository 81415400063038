import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as action from '../../../redux/actions';

class SessionSelect extends Component {

    constructor(props) {
        super(props);
        this.state = {
            optionPay: '0'
        };
    }

    handleChange(event) {
        this.setState({optionPay: event.target.value});
        this.props.dispatch(action.optionPaySelected(event.target.value));
    }

    render() {
        return(
            <select
				name="sessions"
				className="input input-select"
				value={this.state.optionPay}
				onChange={this.handleChange.bind(this)}>
					<option value="0" selected>SELECT AN OPTION</option>
					<option value="16">Normal Session (30min) €16,00 EUR</option>
					<option value="32">Double Session (60min) €32,00 EUR</option>
					<option value="64">Session Pack 1 (120min) €64,00 EUR</option>
					<option value="115">Session Pack 2 (240min) €115,00 EUR</option>
            </select>
        );
    }
}

function mapStateToProps(state) {
    return {
        optionPay: state.optionPay
    }
}

export default connect(mapStateToProps)(SessionSelect);