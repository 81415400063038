import React, { Component } from "react";
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { getTeachers, getHours } from "../../redux/actions";

// Web Components
import Aside from "../../components/user/aside";
import CalendarFilter from "../../components/admin/calendar/calendar-filter";
import CalendarTable from "../../components/admin/tables/calendar-table";
import DefinitionTable from "../../components/admin/tables/definition-table";

class AdminCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      showDefinition: false,
      hours: [
        {
          CLASS_DAY: 1,
          DAY_NAME: "Sunday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        },
        {
          CLASS_DAY: 2,
          DAY_NAME: "Monday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        },
        {
          CLASS_DAY: 3,
          DAY_NAME: "Tuesday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        },
        {
          CLASS_DAY: 4,
          DAY_NAME: "Wednesday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        },
        {
          CLASS_DAY: 5,
          DAY_NAME: "Thursday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        },
        {
          CLASS_DAY: 6,
          DAY_NAME: "Friday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        },
        {
          CLASS_DAY: 7,
          DAY_NAME: "Saturday",
          INIT1: "00:00",
          END1: "00:00",
          INIT2: "00:00",
          END2: "00:00",
          INIT3: "00:00",
          END3: "00:00"
        }
      ]
    };
  }

  componentDidMount() {
    this.load();
  }

  async load() {
    const teachers = await getTeachers();
    if (teachers.teachers) {
      this.setState({ teachers: teachers.teachers });
    }
  }

  setCalendars(data, tTZ) {
    this.setState({
      calendars: data.calendars,
      message: data.message,
      calendarSelected: "",
      calendarSelectedId: "0",
      calendarSelectedActive: false,
      tTZ: tTZ
    });
  }

  setCalendar(calendar, id, active) {
    this.setState({
      calendarSelected: calendar,
      calendarSelectedId: id,
      calendarSelectedActive: active
    });
  }

  async loadHours(calendar) {
    let hours = await getHours({ idSchedule: calendar });
    if (hours.data) {
      console.log(hours);
      this.setState({ hours: hours.data });
    }
  }

  render() {
    let teachers = this.state.teachers ? this.state.teachers : [];

    return (
      <div className="user-admin justify-center">
        <div className="container column">
          <div className="white-space-32" />
          <div className="row-responsive break-row">
            <Aside current={"active-calendar"} />
            {/*  Load Views Here */}
            <div className="admin-area">
              <div className="sessions-page column">
                <h2 className="color-orange" id="to-calendar">CALENDARS</h2>
                <div className="white-space-16" />
                <p>
                  Define your own calendar and set it as active.
                </p>
                <div className="white-space-16" />  
                <div className="row-responsive" id="calendar-inputs">
                  <CalendarFilter teachers={teachers} setCalendars={this.setCalendars.bind(this)} />
                  <CalendarTable calendars={this.state.calendars} setCalendar={this.setCalendar.bind(this)} loadHours={this.loadHours.bind(this)} />
                </div>
                <div className="white-space-32" />                                
                <div>
                  <div>
                    <button className="btn btn-orange" onClick={() => {
                      document.getElementById("modal_new_calendar").classList.remove("close-modal");
                      document.getElementById("modal_new_calendar").classList.remove("hidden");
                      }} >
                      ADD NEW CALENDAR &nbsp;  <i className="fas fa-calendar-plus" />
                    </button>
                  </div>
                  <div className="btn-container">
                    <button className="btn btn-red" onClick={() => {
                      const value = document.getElementById("calendar").value;
                      if (value !== "0") {
                        document.getElementById("modal_delete_calendar").classList.remove("close-modal");
                        document.getElementById("modal_delete_calendar").classList.remove("hidden");
                        }
                      }}>                      
                      DELETE CALENDAR &nbsp;
                      <i className="fas fa-calendar-times"></i>
                    </button>
                  </div>
                </div>
                <DefinitionTable
                  calendarSelected={this.state.calendarSelected}
                  active={this.state.calendarSelectedActive}
                  id={this.state.calendarSelectedId}
                  hours={this.state.hours}
                  user={this.props.user}
                  timezone={this.state.tTZ}
                />
                <div className="white-space-8" />
              </div>
            </div>
          </div>
          <div className="white-space-32" />
        </div>
      </div>
    );
  }

  closeModal() {
    document.getElementById("modal-definition").classList.add("close-modal");
    setTimeout(() => {
      document.getElementById("modal-definition").classList.add("hidden");
    }, 300);
  }
}
function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect( mapStateToProps )( AdminCalendar );