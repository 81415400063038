import React from 'react';

// Pdf's
import Faqs from '../files/BC_Faqs.pdf';
import Jobs from '../files/BC_JobOffer.pdf';
import Terms from '../files/BC_Terms.pdf';

// Images
import Images from '../img/index';

const Footer = () => (

    <footer className="column">
        <div className="footer-top justify-center">
            <div className="container column">
                <div className="row-responsive gutters">
                    <div className="logo">
                        <img src={Images['bonlinelogo']} alt="BéCé Online Logo" title="BéCé Online Logo"/>
                    </div>
                    <div className="footer-nav align-center gutters">
                        <div className="item auto">
                            <a href = {Faqs} className="link">FAQ's</a>
                        </div>
                        <div className="item auto">
                            <a href = {Terms} className="link">Terms & Conditions</a>
                        </div>
                        <div className="item auto">
                            <a href = {Jobs} className="link">We are hiring</a>
                        </div>
                        <div className="item auto">
                            <a href="http://www.bece.cat/" rel="follow" className="link">About BéCé</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-bottom justify-center">
            <div className="container">
                <a href="http://www.dwitmexico.com/" rel="folow" className="color-white link">
                    Developed by Dwit México
                </a>
            </div>
        </div>
    </footer>

);

export default Footer;