import React, { Component } from 'react';
import { URL_API } from '../../../core/urlsApi';
import { buyCredits, updateRole } from "../../../redux/actions";
import Request from '../../../core/httpClient';
// Images
import ImageLoader from "react-loading-image";

const req = new Request();

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            completeCredits: false,
            completeRole: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.ModalUser !== prevProps.ModalUser) {
            this.setState({
				adminSelected: this.props.ModalUser.ADM,
				teacherSelected: this.props.ModalUser.TEACHER
			});
        }
    }

    imgError(event) {
        event.target.src = "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png";
    }

    handleAdmin(event) {
        this.setState({adminSelected: event.target.checked});
    }

    handleTeacher(event) {
        this.setState({ teacherSelected: event.target.checked });
    }

    render() {
        let user = [];
        if(this.props.ModalUser) {
			user = this.props.ModalUser;
			const name = document.getElementById('name');
			if (name) {
				name.value = user.NAME;
			}
			const zoom_id = document.getElementById('zoom_id');
			if (zoom_id) {
				zoom_id.value = user.ZOOM;
			}           
        }
        return(
            <div className="flex modal justify-center hidden" id="modal-users">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            EDIT USER
                        </h2>
                        <div className="close-x justify-end">
                            <h3 className="color-white" onClick={this.closeModal.bind(this)}>x</h3>
                        </div>
                    </div>
                    <div className="modal-body column">
                        <div className="white-space-16"></div>
                        <div className="confirmation-data column">
                            <div className="teacher-picture auto">
                                <ImageLoader src={URL_API + "/users/image/?idImage=" + user.IDIMAGE} 
                                    image={props => <img src={props.src} alt="Teacher's Profile Picture" />}
                                    loading={() => <img src="https://secure.moneypak.com/cmsfolder/content/images/ring-alt_1.gif" alt="loading" />}
                                    onError={this.imgError.bind(this)} />
                            </div>
                            <div className="white-space-16"></div>
                            <div className="white-space-16"></div>
                            <form onSubmit={this.updateProfile.bind(this, user)} className="column align-center">
								<div className="container">
                                    <div className="column">
                                        <div className="row justify-start align-center">
                                            <p>
                                                Name:
                                            </p>
                                            <input id="name" style={{ width: '100%' }} className="input input-text color-orange weight-bold text-center" required></input>
                                        </div>
                                        <div className="white-space-16"></div>
										<div className="row justify-start align-center">
                                            <p>
                                                Zoom ID:
                                            </p>
                                            <input id="zoom_id" style={{ width: '100%' }} className="input input-text color-orange weight-bold text-center"></input>
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="row justify-center align-center">
                                            <p>
                                                Roles:
                                            </p>
                                            <input type="checkbox" name="adminrole" checked={this.state.adminSelected} onChange={this.handleAdmin.bind(this)} />&nbsp; Admin &nbsp;
                                            &nbsp;<input type="checkbox" name="teacherrole" checked={this.state.teacherSelected} onChange={this.handleTeacher.bind(this)} /> &nbsp; Teacher
                                        </div>
                                        <div className="white-space-16"></div>
                                        <div className="row-responsive justify-center align-center">
                                            <p>
                                                Credits: <b>
                                                    {user.CREDITS}
                                                </b>
                                            </p>
                                            <p id="add-credits">
                                                Add credits in minutes:
                                            </p>
                                            <input name="amount" className="input input-select" type="number"></input>
                                        </div>
                                    </div>
                                </div>
                                <div className="white-space-24"></div>
                                <div className="controls-group">
                                    <div className="accept">
                                        <button type="submit" className="btn btn-orange">
                                            <i className="fas fa-check-double"></i>&nbsp; CONFIRM
                                        </button>
                                    </div>
                                    <div className="cancel">
                                        <button className="btn btn-red" data-close="users" onClick={this.closeModal.bind(this)}>
                                            <i className="fas fa-times"></i>&nbsp; CLOSE
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async updateProfile(user, event) {
        event.preventDefault();
		let validCredits;
		const form = event.target;
        if(form.amount.value != 0) {
            validCredits = form.amount.value;
        }
        let data = {
            email: user.USERNAME,
            type: 'M',
            credits: validCredits
        };
        let roles = {
            email: user.USERNAME,
            teacher: form.teacherrole.checked,
			admin: form.adminrole.checked,
			name: form.name.value,
			zoom_id: form.zoom_id.value
        }
        if(validCredits) {
            this.buyMethod(data);
            this.updateRoleMethod(roles);            
        } else {
            this.setState({ completeCredits: true });
            this.updateRoleMethod(roles);
        }
    }

    async buyMethod(data) {
		const res = await req.post('/credits/manual', data);
		console.log(res);
		if (!res.inserted) {
			alert(res.message || 'Error on credit assign');
		}
    }

    async updateRoleMethod(roles) {
        const res = await req.post('/users/update/role', roles);
        if (res.updated) {
            this.setState({ message: res.message });
            alert(res.message || "Successfully updated user");
            window.location.reload();
        } else {
			alert(res.message || "Couldn't update user roles");
		}
    }

    closeModal(event) {
        event.preventDefault();
        document.getElementById("modal-users").classList.add("close-modal");
        setTimeout(() => {
            document.getElementById("modal-users").classList.add("hidden");
        }, 300);
    }
}

export default Modal;