import React, { Component } from "react";
import moment from "moment-timezone";
import { connect } from 'react-redux';
import Request from '../../../core/httpClient';
const skypeColor = { color:  '#0078d7'};
const req = new Request();

class Modal extends Component {

    constructor(props) {
        super(props);
        this.state = {
			clase: {}
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.session !== this.props.session) {
			this.loadSession(this.props.session);
		}
    }

	async loadSession(id_session) {
		this.setState({ loadingSession: true, message: '', clase: {} });
		const res = await req.post('/sessions/getbyid', {id_session});
		console.log(res);
		if (!res.error) {
			this.setState({clase: res});
		} else {
			this.setState({message: res.message || 'NOT SESSION FOUND'})
		}
		this.setState({loadingSession: false, message: ''});
	}

	render() {
		const {clase} = this.state;
        return (            
            <div className="flex modal justify-center hidden" id="modal-credit-session">
                <div className="container modal-content column">
                    <div className="modal-header justify-center">
                        <h2 className="color-white">
                            SESSION INFO.
                        </h2>
						<div className="close-x justify-end">
							<h3 className="color-white" onClick={this.closeModal.bind(this)}>x</h3>
						</div>
                    </div>
                    <div className="modal-body column justify-center align-center">
                        <div className="white-space-32"></div>
                        <div className="container">
							<div className="confirmation-data column">
								<div className="flex row justify-between">
									<div className="column">
										<p className="text-mini">
											Teacher: <b> {clase.TEACHER} </b>
										</p>
										<div className="white-space-8"></div>
										<p className="text-mini">
											Start Time:&nbsp;
										<b>
												{
													`${moment(clase.CLASS_DATETIME).tz(this.props.timezone.utc).format("YYYY-MM-DD")} 
												${moment(clase.CLASS_DATETIME).tz(this.props.timezone.utc).format("HH:mm a")}`
												}
											</b>
										</p>
									</div>
									<div className="column">
										<p className="text-mini">
											Student: <b> {clase.STUDENT} </b>
										</p>
										<p className="text-mini">
											Skype:&nbsp;
										<a href={`skype:${clase.STUDENT_SKYPE}?call`}>
												<i className="fab fa-2x fa-skype color-orange" style={skypeColor}></i>
												<b> {clase.STUDENT_SKYPE} </b>
											</a>
										</p>
									</div>
								</div>
								<div className="white-space-16"></div>
								<div className="request justify-center">
									<textarea name="request" rows="8" disabled={true} className="input textarea" maxLength="800" placeholder="User comments"
										value={clase.COMMENTS}>
									</textarea>
								</div>
								<div className="white-space-16"></div>
								<div className="request justify-center">
									<textarea name="request" rows="8" disabled={true} className="input textarea" maxLength="800" placeholder="Teacher comments"
										value={clase.TEACHER_COMMENTS}>
									</textarea>
								</div>
								<div className="white-space-16"></div>
								{
									this.props.teacher ? (
										<div className="row justify-center align-center">
											<div className="row align-center">
												<p>
													Status: &nbsp;
                                            </p>
												<select name="status" id="select-status" className="input input-select" value={this.state.clase.IDSTATE} disabled={true}>
													<option value="1">OPEN</option>
													<option value="2">CANCELLED BY STUDENT</option>
													<option value="3">FINISHED</option>
													<option value="4">FAILED</option>
													<option value="5">CANCELLED BY TEACHER</option>
													<option value="6">NO FEEDBACK</option>
												</select>
											</div>
											<div className="row full justify-start align-center">
												{clase.IDSTATE == 5 &&
													<input style={{ width: '90%' }} className="input input-text" placeholder="please, write the reason of your cancellation" disabled={true}
														value={clase.CANCEL_REASON || ''}
													></input>
												}
												{clase.IDSTATE == 2 &&
													<input style={{ width: '90%' }} className="input input-text" placeholder="No studen cancell reason" disabled={true}
														value={clase.CANCEL_REASON || ''}
													></input>
												}
											</div>
										</div>
									) : (null)
								}
								<div className="white-space-8"></div>
								<p>
									{this.state.message}
								</p>
							</div>
						</div>
                        <div className="white-space-24"></div>
                        {/*<div className="controls-group">
                            <div className="cancel">
								{this.state.loadingSession?
									<button className="btn btn-red" data-close="book" >
										<i className="fas fa-spinner fa-spin" />
									</button>
									: 
									<button className="btn btn-red" data-close="book" onClick={this.closeModal.bind(this)} >
										<i className="fas fa-times" />
										&nbsp; CLOSE
									</button>
								}
                            </div>
						</div>*/}
                    </div>
                </div>
            </div>
        );
    }

    closeModal() {
        document.getElementById("modal-credit-session").classList.add("close-modal");
        setTimeout(() => {
            document.getElementById("modal-credit-session").classList.add("hidden");
		}, 300);
		if (this.props.onClose) {
			this.props.onClose();
		}
    }
}

function mapStateToProps(state) {
    return {
        timezone: state.user,
        teacher: state.user.teacher,
		admin: state.user.admin,
		user: state.user
    }
}

export default connect(mapStateToProps)(Modal);
