import * as types from "./constants";

const initialState = {
  auth: false,
  optionPay: '',
  users: {}, user: {}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    /* ==========================================================================
    USERS
========================================================================== */

    case types.LOGIN:
      return {
        ...state,
        login_loading: true
      };

    case types.LOGIN_SUCCESS:
      return {
        ...state,
        auth: action.data.user.auth || false,
        user: action.data.user,
        login_loading: false
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        auth: false,
        login_loading: false
      };

    case types.LOGOUT:
      return {
        ...state,
        auth: false
      };

    case types.REGISTER:
      return {
        ...state,
        register_loading: true
      };

    case types.REGISTER_SUCCESS:
      return {
        ...state,
        auth: action.data.data.auth || false,
        register_loading: false,
        user: action.data.data
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        register_loading: false
      };

    case types.UPDATEUSER:
      return {
        ...state,
        updateuser_loading: true
      };

    case types.UPDATEUSER_SUCCESS:
      return {
        ...state,
        updateuser_loading: false,
        user: action.data.data? action.data.data : state.user
      };
    case types.UPDATEUSER_ERROR:
      return {
        ...state,
        updateuser_loading: false
      };

    /* ==========================================================================
    NATIONALITIES
========================================================================== */

    case types.GET_NATIONALITIES:
      return {
        ...state,
        nationalities_loading: true
      };

    case types.GET_NATIONALITIES_SUCCESS:
      return {
        ...state,
        nationalities: action.data,
        nationalities_loading: false
      };
    case types.GET_NATIONALITIES_ERROR:
      return {
        ...state,
        nationalities_loading: false
      };

    /* ==========================================================================
    TIMEZONES
========================================================================== */

    case types.GET_UTC:
      return {
        ...state,
        utc_loading: true
      };

    case types.GET_UTC_SUCCESS:
      return {
        ...state,
        utc: action.data,
        utc_loading: false
      };
    case types.GET_UTC_ERROR:
      return {
        ...state,
        utc_loading: false
      };
    /* ==========================================================================
    TOPICS
========================================================================== */
    case types.GET_TOPICS:
      return {
        ...state,
        topics_loading: true
      };

    case types.GET_TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.data,
        topics_loading: false
      };
    case types.GET_TOPICS_ERROR:
      return {
        ...state,
        topics_loading: false
      };

    /* ==========================================================================
    SESSIONS
========================================================================== */
    case types.GET_SESSIONS:
      return {
        ...state,
        sessions_loading: true
      };

    case types.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: action.data,
        sessions_loading: false
      };
    case types.GET_SESSIONS_ERROR:
      return {
        ...state,
        sessions_loading: false
      };

/* ==========================================================================
    CREDITS
========================================================================== */

    case types.OPTIONPAY:
    return {
      ...state,
      optionPay: action.data
    }

    case types.GET_CREDITS:
      return {
        ...state,
        credits_loading: true
      };

    case types.GET_CREDITS_ERROR:
   
      return {
        ...state,
        credits_loading: false
      };

    case types.GET_CREDITS_SUCCESS:
      return {
        ...state,
        credits_loading: false,
        credits: action.data.credits
      };


    default:
      return {
        ...state
      };
  }
};

export default reducer;
