import React from 'react';
import { Link } from 'react-router-dom';
import Faqs from '../files/BC_Faqs.pdf';

// Images
import Images from '../img/index';

const Pricing = () => (

    <div className="introduction justify-center">
        <div className="container">
            <form className="column">
                <div className="row-responsive">
                    <div className="left column">
                        <img src={Images['testimonial_5']} alt="Hello" title="Hello" />
                    </div>

                    <div className="right column">
                        <div className="white-space-16"></div>
                        <h1 className="color-orange" id="to-pricing">
                            PRICING
                        </h1>
                        <div className="white-space-32"></div>
                        <div className="table-responsive">
                            <table className="table-price">
                                <thead>
                                    <tr>
                                        <th>Credit</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>NOW!</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>30 minutes</td>
                                        <td>16.00€</td>
                                        <td></td>
                                        <td className="weight-bold">16.00€</td>
                                    </tr>
                                    <tr className="color-orange">
                                        <td>60 minutes</td>
                                        <td>32.00€</td>
                                        <td></td>
                                        <td className="weight-bold">32.00€</td>
                                    </tr>
                                    <tr>
                                        <td>120 minutes</td>
                                        <td>64.00€</td>
                                        <td></td>
                                        <td className="weight-bold">64.00€</td>
                                    </tr>
                                    <tr>
                                        <td>240 minutes</td>
                                        <td>128.00€</td>
                                        <td>10%</td>
                                        <td className="weight-bold">115.00€</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="white-space-32"></div>
                        <a href = { Faqs } className="link">
                            Need help? Check the FAQ's
                        </a>
                        <div className="white-space-16"></div>
                        <div className="justify-between row-responsive" id="pricing-buttons">
                            <div className="btn-container auto">
                                <Link to="/register" className="btn btn-orange">
                                    <i className="fas fa-plus-circle"></i>&nbsp; CREATE AN ACCOUNT
                                </Link>
                            </div>
                            <div className="btn-container auto">
                                <Link to="/login" className="btn btn-orange">
                                    <i className="fas fa-user"></i>&nbsp; ACCESS NOW
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="white-space-16"></div>
            </form>
        </div>
    </div>

);

export default Pricing;