import React, { Component } from "react";
import { connect } from "react-redux";
import { HashLink as Link } from "react-router-hash-link";

// Images
import Images from "../img/index";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  logout() {
    this.props.dispatch({type: 'LOGOUT'})
  }
  render() {
    return (
      <header className="column">
        <div className="justify-center">
          <div className="container">
            <div className="row-responsive">
              <div className="logo auto">
                <a href="/">
                  <img src={Images["becelogo"]} alt="BéCé Online Logo" title="BéCé Online Logo" />
                </a>
              </div>
              <nav className="justify-end">
                {this.props.auth ? (
                  <div className="row align-center auto">
                    <div className="nav-link align-center auto">
                      <Link to="/user/profile" className="align-center" id="user">
                        Hi, {this.props.user.name} &nbsp;<i className="fas fa-hand-paper" />
                      </Link>
                    </div>
                    <div className="nav-link align-center auto" onClick={this.logout.bind(this)}>
                      <a href="#">
                        LOG OUT&nbsp;<i className="fas fa-sign-out-alt" />
                      </a>
                    </div>
                  </div>
                ) : (
                    <div className="row align-center auto">
                      <div className="nav-link align-center auto">
                        <Link to="/pricing#to-pricing" className="align-center">
                          PRICING&nbsp;
                          <i className="fas fa-star-of-life" />
                        </Link>
                      </div>
                      <div className="nav-link align-center auto">
                        <Link to="/login#to-login">
                          SIGN IN&nbsp;
                          <i className="fas fa-user" />
                        </Link>
                      </div>
                      <div className="nav-link align-center auto">
                        <Link to="/register">
                          SIGN UP&nbsp;
                          <i className="fas fa-plus-circle" />
                        </Link>
                      </div>
                    </div>
                  )}
              </nav>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user
  };
}
export default connect(mapStateToProps)(Header);
