import React from 'react';
import { Link } from 'react-router-dom';

const AsideCredit = (props) => (
    <div className="credit-aside column">
        <div className="credit-head auto">
            <h4 className="color-white">
                <i className="fas fa-clock"></i>&nbsp; AVAIBLE CREDIT
            </h4>
        </div>
        <p className="text-center weight-bold">
        {
            props.credit ? `${props.credit} minutes` :
            'No credits available'
        }            
        </p>
        <Link to="/user/buy" className="btn btn-orange text-center full">
            BUY MORE
        </Link>
    </div>
); 

export default AsideCredit;