import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import Request from '../../../core/httpClient';
import Paginator from '../../paginator';

const req = new Request();

class ModalUserSessions extends Component {

    constructor(props) {
        super(props);
        this.state = {
			sessions: [],
			page: 1
        }
	}

	componentDidUpdate(prevProps) {
		if (this.props.user !== prevProps.user) {
			this.loadSessions();
		}
	}

	async loadSessions() {
		this.setState({loading: true});
		const { USERNAME } = this.props.user;
		const res = await req.post('/sessions/getbyuser', { USERNAME });
		console.log(res);
		if (res.data) {
			this.setState({sessions: Array.isArray(res.data)? res.data : [] });
		} else {
			this.setState({sessions: [], message: res.message || 'No sessions were found' });
		}
		this.setState({loading: false});
	}

	setpage(page) {
		this.setState({ page });
	}

    render() {
		let sessions = [], sessions_length = 0;
		if (this.state.sessions) {
			sessions_length = this.state.sessions.length;
			sessions = this.state.sessions.slice(((this.state.page - 1) * 9), (this.state.page * 9));
		}

        return(
            <div className="column justify-center align-center">
				<table className="table-sessions table-auto" id="table-admin-sessions">
					<thead>
					<tr>
						<th> Start Time </th>
						<th> Duration </th>
						<th> Status </th>
						<th> Teacher </th>
						{/*<th> Quantity </th>*/}
					</tr>
					</thead>
					<tbody>
						{this.state.lading?
							<tr>
								<td colSpan="5">
									<i className="fas fa-sync fa-spin"></i>
								</td>
							</tr>
							:
							sessions.length > 0 ?
								sessions.map((session, index) =>
									<tr key={index}>
										<td>{moment(session.CLASS_DATETIME).tz(this.props.userAdmin.utc).format('YYYY-MM-DD HH:mm a')}</td>
										<td>{`${session.DURATION} minutes`}</td>
										<td>{session.STATUS}</td>
										<td>{session.TEACHER}</td>
									</tr>
								)
								:
								<tr>
									<td colSpan="5">
										{this.state.message}
									</td>
								</tr>
						}
					</tbody>
				</table>
				<div className="white-space-16"></div>
				{sessions_length > 9 ? <Paginator pagina={this.state.page} setpage={this.setpage.bind(this)} items={Math.ceil(sessions_length / 9)} /> : null}
			</div>
        );
    }
}

function mapStateToProps(state) {
	return {
		userAdmin: state.user
	}
}

export default connect(mapStateToProps)(ModalUserSessions);